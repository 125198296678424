import React from 'react';
import AboutSection from '../components/AboutUs/AboutSection';
import ValuesSection from '../components/AboutUs/ValuesSection';
import MissionSection from '../components/AboutUs/MissionSection';
import CompanyHistory from '../components/AboutUs/CompanyHistory';
import OurTeam from '../components/AboutUs/OurTeam';
import TestimonialSection from '../components/AboutUs/TestimonialSection';




const AboutPage = () => {
  return (
    <div className="flex overflow-hidden flex-col items-center bg-white">
      <main>
        <AboutSection />
        <ValuesSection />
        <MissionSection />
        <CompanyHistory />
        <OurTeam />
        <TestimonialSection />
      </main>
    </div>
  );
};

export default AboutPage;