import React from 'react';
import contentIcon from '../../assets/img/Content.png';

const AboutSection = () => {
  return (
    <section data-aos="zoom-in" data-aos-duration="3000" className="flex flex-col items-center self-stretch px-20 pt-10 pb-20 mt-14 w-full text-[20px] text-center text-black bg-teal-500 bg-opacity-30 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div style={{ fontFamily: 'roboto, sans-serif' }} className="flex flex-col max-w-full">
        <h1 data-aos="fade-up" data-aos-duration="3000" style={{ fontFamily: 'roboto, sans-serif' }} className="mt-16 text-[64px] leading-normal text-center text-black max-md:mt-10 max-md:text-4xl">
          About Us
        </h1>
      </div>
    </section>
  );
};

export default AboutSection;